import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import Layout from '../components/Layout';

const PartnersPage = () => (
	<Layout title='Partners'>
		<div className='px-3 col-lg-9 mx-auto mb-5'>
			<h2 className='display-5 mt-3 raleway-700'>Our Partners</h2>
			<p>
				ASGSR Students collaborates with a number of national and international organizations that share the
				vision and objectives of ASGSR. The goal of these partnerships is to bridge the gap between students of
				all levels and the different actors of the microgravity research community. Interested in working with
				us? Just send an email to the <Link to='/about/team'>President of ASGSR Students</Link> !
			</p>

			<div className='row justify-content-between align-items-center my-3'>
				<div className='col-md-4 col-lg-3'>
					<a target='_blank' href='https://www.unoosa.org/'>
						<div className='text-center text-md-start mt-5'>
							<StaticImage
								src='../images/partners/unoosa-1.png'
								alt='UNOOSA'
								formats={['auto', 'webp']}
								placeholder='dominantColor'
								width={250}
							/>
						</div>
					</a>
				</div>

				<div className='col-md-8'>
					<h3 className='raleway-700 mt-3'>The United Nations Office for Outer Space Affairs (UNOOSA)</h3>
					<p>
						The United Nations Office for Outer Space Affairs (UNOOSA) works to promote international
						cooperation in the peaceful use and exploration of space, and in the utilization of space
						science and technology for sustainable economic and social development. Of particular interest
						to ASGSR Students is the{' '}
						<a target='_blank' href='https://www.unoosa.org/oosa/en/ourwork/access2space4all/index.html'>
							Access to Space for All initiative
						</a>{' '}
						of UNOOSA and space agencies, research institutions, and industry, that offers access to space
						research facilities, infrastructure and information with the aim of developing technical
						know-how, engineering processes and infrastructure in the areas of hypergravity and
						microgravity, satellite development and space exploration, and promote international cooperation
						in the peaceful uses of outer space.
					</p>
				</div>
			</div>

			<div className='row justify-content-between align-items-center my-3'>
				<div className='col-md-4 order-md-1'>
					<a target='_blank' href='https://sites.google.com/site/studentelgra/'>
						<div className='text-center text-md-start mt-5'>
							<StaticImage
								src='../images/partners/selgra.png'
								alt='SELGRA'
								formats={['auto', 'webp']}
								placeholder='dominantColor'
								width={250}
							/>
						</div>
					</a>
				</div>
				<div className='col-md-8'>
					<h3 className='raleway-700 mt-3'>Student European Low-Gravity Research Association (SELGRA)</h3>
					<p>
						<a target='_blank' href='https://sites.google.com/site/studentelgra/'>
							SELGRA
						</a>{' '}
						is the student chapter of the European Low Gravity Research Association aiming to be the point
						of reference for gravity-related students all over Europe. SELGRA organises and supports
						outreach activities as well as its members to present and promote their research.
					</p>
				</div>
			</div>

			<div className='row justify-content-between align-items-center my-3'>
				<div className='col-md-4 col-lg-3'>
					<a target='_blank' href='https://fairchildgarden.org/gbe/'>
						<div className='text-center text-md-start mt-5'>
							<StaticImage
								src='../images/partners/fairchild-gbe.gif'
								alt='Fairchild Growing Beyond Earth'
								formats={['auto', 'webp']}
								placeholder='dominantColor'
								width={250}
							/>
						</div>
					</a>
				</div>
				<div className='col-md-8'>
					<h3 className='raleway-700 mt-3'>Fairchild's Growing Beyond Earth (GBE)</h3>
					<p>
						<a target='_blank' href='https://fairchildgarden.org/gbe/'>
							Growing Beyond Earth (GBE)
						</a>{' '}
						is Fairchild’s NASA-funded classroom science project designed to advance research on growing
						plants aboard spacecraft. As NASA looks toward a long-term human presence beyond Earth orbit,
						there are specific science, technology, engineering, and math (STEM) challenges related to food
						production. GBE is addressing those challenges by expanding the diversity and quality of edible
						plants that can be grown in space. On Earth, GBE is also improving technologies for gardening in
						urban, indoor, and other resource-limited settings.
					</p>
				</div>
			</div>

			<div className='row justify-content-between align-items-center my-3'>
				<div className='col-md-4 order-md-1'>
					<a target='_blank' href='https://eccbn.org/'>
						<div className='text-center text-md-start mt-5'>
							<StaticImage
								src='../images/partners/eccbn.png'
								alt='ECCBN'
								formats={['auto', 'webp']}
								placeholder='dominantColor'
								width={250}
							/>
						</div>
					</a>
				</div>
				<div className='col-md-8'>
					<h3 className='raleway-700 mt-3'>Colorado Council of Black Nurses (CCBN)</h3>
					<p>
						<a target='_blank' href='https://eccbn.org/'>
							The Colorado Council of Black Nurses, Inc. (CCBN)
						</a>{' '}
						is a 501(c)3 nonprofit, philanthropic organization that has been a staple in the Colorado health
						community for over 45 years. Composed of a multi-generational network of leaders, pioneers,
						visionaries, and students in the profession of nursing and nurse pathway careers, CCBN addresses
						health equity from a cultural perspective with a focus on improving the health status of ALL
						people.
					</p>
				</div>
			</div>

			<div className='row justify-content-between align-items-center my-3'>
				<div className='col-md-4'>
					<a target='_blank' href='https://spacegeneration.org/'>
						<div className='text-center text-md-start mt-5'>
							<StaticImage
								src='../images/partners/sgac.png'
								alt='SGAC'
								formats={['auto', 'webp']}
								placeholder='dominantColor'
								width={250}
							/>
						</div>
					</a>
				</div>
				<div className='col-md-8'>
					<h3 className='raleway-700 mt-3'>Space Generation Advisory Council (SGAC)</h3>
					<p>
						<a target='_blank' href='https://spacegeneration.org/'>
							The Space Generation Advisory Council
						</a>{' '}
						in Support of the United Nations Programme on Space Applications is a global non-governmental,
						non-profit (US 501(c)3) organization and network which aims to represent university students and
						young space professionals ages 18-35 to the United Nations, space agencies, industry, and
						academia. The SGAC network of members, volunteers and alumni has grown to more than 16,000
						members representing more than 165 countries.
					</p>
				</div>
			</div>

			<div className='row justify-content-between align-items-center my-3'>
				<div className='col-md-4 order-md-1'>
					<a href='https://www.amsro.org/' target='_blank'>
						<div className='text-center text-md-start mt-5'>
							<StaticImage
								src='../images/partners/amsro.png'
								alt='AMSRO'
								formats={['auto', 'webp']}
								placeholder='dominantColor'
								width={250}
							/>
						</div>
					</a>
				</div>
				<div className='col-md-8'>
					<h3 className='raleway-700 mt-3'>Aerospace Medicine Student and Resident Organization (AMSRO)</h3>
					<p>
						<a href='https://www.amsro.org/' target='_blank'>
							The Aerospace Medicine Student and Resident Organization (AMSRO)
						</a>{' '}
						is an expanding group of upcoming professionals in aerospace medicine that disseminate
						information regarding career and educational opportunities to its membership. AMSRO provides a
						forum for the unification of undergraduate students, graduate students, professional students,
						and medical residents interested in aerospace- related fields and for the exchange of insights
						regarding training programs, research opportunities, and general career development. As the
						official student and resident affiliate of the Aerospace Medical Association, it provides
						guidance to the Association on matters of interests to students and residents.
					</p>
				</div>
			</div>
		</div>
	</Layout>
);

export default PartnersPage;
